import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class ExamService {
    constructor(private http: HttpClient) { }

    getExamList(params) {
        return this.http.get(
            environment.apiEndpoint + `exam/student/list?${params}`
        );
    }

    getExamCategory(params) {
        return this.http.get(
            environment.apiEndpoint + `exam-categories?${params}`
        );
    }

    getExamDetail(id) {
        return this.http.post(
            environment.apiEndpoint + `exam/student/${id}`, {}
        );
    }

    submitExamStudent(payload) {
        return this.http.patch(
            environment.apiEndpoint + "exam/student/submit", payload
        );
    }

    getExamAnswer(examId: string) {
        return this.http.get(
            environment.apiEndpoint + `exam/student/answers/${examId}`
        );
    }
}
