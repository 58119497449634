import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";

import { AuthService } from "@services";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router, public authService: AuthService) { }

    canActivate(): Promise<boolean> {
        return new Promise((resolve) => {
            this.authService.isAuthenticated().then((authenticated: boolean) => {
                if (!authenticated) {
                    this.router.navigate(["login"]);
                    resolve(false);
                }
                
                resolve(true);
            });
        });
    }

}
