import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

import { IconSetService } from "@coreui/icons-angular";
import { cilUser } from "@coreui/icons";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

import { EventEnum, ToastrEnum } from "@enums";
import { IEvent } from "@interfaces";
import { EventService } from "@services";

@Component({
    // tslint:disable-next-line
    selector: "body",
    template:
        "<router-outlet></router-outlet>" +
        "<ngx-spinner template=\"" +
        "<img class='img-spinner' src=https://assets.eduku.io/misc/eduku_spinner.gif />\">" +
        "</ngx-spinner>",
    providers: [IconSetService],
})
export class AppComponent implements OnInit {
    constructor(
        private router: Router,
        public iconSet: IconSetService,
        private toastrService: ToastrService,
        private eventService: EventService,
        private spiner: NgxSpinnerService
    ) {
        // iconSet singleton
        iconSet.icons = { cilUser };
    }

    ngOnInit(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });

        this.initGlobalEvent();
    }

    initGlobalEvent(): void {
        this.eventService.getObservable().subscribe((event: IEvent) => {
            if (event.type == EventEnum.TOASTR) {
                switch (event.toastr.type) {
                case ToastrEnum.SUCCESS:
                    this.toastrService.success(
                        event.toastr.message,
                        event.toastr.title,
                        event.toastr.config
                    );
                    break;
                case ToastrEnum.ERROR:
                    this.toastrService.error(
                        event.toastr.message,
                        event.toastr.title,
                        event.toastr.config
                    );
                    break;
                case ToastrEnum.WARNING:
                    this.toastrService.warning(
                        event.toastr.message,
                        event.toastr.title,
                        event.toastr.config
                    );
                    break;
                case ToastrEnum.INFO:
                    this.toastrService.info(
                        event.toastr.message,
                        event.toastr.title,
                        event.toastr.config
                    );
                    break;
                }
            }

            if (event.type == EventEnum.IS_LOADING) {
                if (event.loading) {
                    this.spiner.show();
                } else {
                    this.spiner.hide();
                }
            }
        });
    }
}
