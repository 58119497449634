import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env";

@Injectable({
    providedIn: "root",
})
export class UserService {
    constructor(private http: HttpClient) { }

    get(id) {
        return this.http.get(`${environment.apiEndpoint}user/${id}`);
    }

    update(id, param) {
        return this.http.patch(`${environment.apiEndpoint}user/${id}`, param);
    }

    getProfileById(id) {
        return this.http.get(`${environment.apiEndpoint}profile/${id}`);
    }
}
