import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { IEvent } from "@interfaces";

@Injectable({
    providedIn: "root"
})
export class EventService {

    private loadingSubject = new Subject<IEvent>();

    publish(data: IEvent) {
        this.loadingSubject.next(data);
    }

    getObservable(): Subject<IEvent> {
        return this.loadingSubject;
    }
}