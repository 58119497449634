import { Injectable } from "@angular/core";
import { BsDatepickerConfig, BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { ModalOptions } from "ngx-bootstrap/modal";

@Injectable()
export class ConfigService {
    static getDatepickerConfig(): BsDatepickerConfig {
        return Object.assign(new BsDatepickerConfig(), {
            dateInputFormat: "DD/MM/YYYY",
            containerClass: "theme-edu-primary",
            showWeekNumbers: false
        });
    }

    static getDaterangepickerConfig(): BsDaterangepickerConfig {
        return Object.assign(new BsDaterangepickerConfig(), {
            rangeInputFormat: "DD/MM/YYYY",
            containerClass: "theme-edu-primary",
            showWeekNumbers: false
        });
    }

    static getModalConfig(): ModalOptions {
        return Object.assign(new ModalOptions(), {
            animation: true,
            backdrop: true,
            ignoreBackdropClick: true
        });
    }
}