import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env";

@Injectable({
    providedIn: "root",
})
export class QuizService {
    constructor(private http: HttpClient) {}

    getQuizStudents(params) {
        return this.http.get(
            environment.apiEndpoint + `student/quiz/list-week?${params}`
        );
    }

    getQuizDetails(params) {
        return this.http.get(
            environment.apiEndpoint +
                `student/quiz/${params.quizId}?classroom=${params.classroomId}`
        );
    }

    submitQuizStudent(data) {
        return this.http.post(
            environment.apiEndpoint + "student/quiz/submit",
            data
        );
    }
}
