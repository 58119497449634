import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env";
import { IClassesProfile } from "@interfaces";

@Injectable({
    providedIn: "root",
})
export class ClassService {
    constructor(private http: HttpClient) { }

    getClassProfileFromCode(code) {
        return this.http.get<IClassesProfile>(`${environment.apiEndpoint}class/profile/${code}`);
    }

}