import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "@guards";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./pages/error/404.component";
import { P500Component } from "./pages/error/500.component";

export const routes: Routes = [
    {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
    },
    {
        path: "404",
        component: P404Component,
        data: {
            title: "Page 404",
        },
    },
    {
        path: "500",
        component: P500Component,
        data: {
            title: "Page 500",
        },
    },
    {
        path: "login",
        loadChildren: () =>
            import("./pages/login/login.module").then((m) => m.LoginModule),
    },
    {
        path: "verify-google",
        loadChildren: () =>
            import("./pages/google/google.module").then((m) => m.GoogleModule),
    },
    {
        path: "register",
        loadChildren: () =>
            import("./pages/register/register.module").then(
                (m) => m.RegisterModule
            ),
    },
    {
        path: "forgot-password",
        loadChildren: () =>
            import("./pages/forgot-password/forgot-password.module").then(
                (m) => m.ForgotPasswordModule
            ),
    },
    {
        path: "user-verify",
        loadChildren: () => import("./pages/verification/verification.module").then(m => m.VerificationModule)
    },
    {
        path: "",
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Home",
        },
        children: [
            {
                path: "dashboard",
                loadChildren: () =>
                    import("./pages/dashboard/dashboard.module").then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: "profile",
                loadChildren: () =>
                    import("./pages/profile/profile.module").then(
                        (m) => m.ProfileModule
                    ),
            },
            {
                path: "schedule",
                loadChildren: () =>
                    import("./pages/schedule/schedule.module").then(
                        (m) => m.ScheduleModule
                    ),
            },
            {
                path: "notifications",
                loadChildren: () =>
                    import("./pages/notifications/notifications.module").then(
                        (m) => m.NotificationsModule
                    ),
            },
            {
                path: "task",
                data: {
                    module: "/task",
                },
                loadChildren: () =>
                    import("./pages/task/task.module").then(
                        (m) => m.TaskModule
                    ),
            },
            {
                path: "module",
                loadChildren: () =>
                    import("./pages/module/module.module").then(
                        (m) => m.ModuleModule
                    ),
            },
            {
                path: "quiz",
                data: {
                    module: "/quiz",
                },
                loadChildren: () =>
                    import("./pages/quiz/quiz.module").then(
                        (m) => m.QuizModule
                    ),
            },
            {
                path: "exam",
                data: {
                    module: "/exam",
                },
                loadChildren: () =>
                    import("./pages/exam/exam.module").then(
                        (m) => m.ExamModule
                    ),
            },
            {
                path: "score",
                data: {
                    module: "/score",
                },
                loadChildren: () =>
                    import("./pages/score/score.module").then(
                        (m) => m.ScoreModule
                    ),
            },
            {
                path: "evaluation",
                data: {
                    module: "/evaluation",
                },
                loadChildren: () =>
                    import("./pages/evaluation/evaluation.module").then(
                        (m) => m.EvaluationModule
                    ),
            },
        ],
    },
    { path: "**", component: P404Component },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
