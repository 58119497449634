import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "username"
})
export class UsernamePipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): unknown { // eslint-disable-line
        if (value) {
            const splittedName = value.toUpperCase().split(" ");
            if (splittedName.length >= 2) {
                const firstName = splittedName[0];
                const lastName = splittedName[splittedName.length - 1];
                return firstName[0] + lastName[0];
            } else {
                const firstName = splittedName[0];
                return firstName[0] + firstName[firstName.length - 1];
            }
        } else {
            return "";
        }
    }

}
