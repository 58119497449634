import { Injectable } from "@angular/core";
import * as moment from "moment";

import { Page } from "@models";

@Injectable({
    providedIn: "root",
})
export class HelperService {
    // helper enum
    getEnumValueString(enums) {
        const values = Object.values(enums);
        return values;
    }

    getEnumKeyString(enums) {
        const keys = Object.keys(enums);
        return keys;
    }

    getEnumValueNumeric(enums) {
        const values = Object.keys(enums).filter((v) => !isNaN(Number(v)));
        return values;
    }

    getEnumKeyNumeric(enums) {
        const keys = Object.keys(enums).filter((v) => isNaN(Number(v)));
        return keys;
    }

    // helper pagination
    toPaginationParameter(page: Page) {
        const param: { [key: string]: string | number | Array<string> } = {
            limit: page.size,
            page: page.pageNumber,
        };

        if (page.join) {
            param.join = page.join;
        }
        if (page.q) {
            param.s = page.q;
        }
        if (page.filter) {
            param.filter = page.filter;
        }
        if (page.sort) {
            param.sort = page.sort;
        }

        return param;
    }

    changeFormatDate(date: string, format: string) {
        return `${moment(date).format(format)}`;
    }

    parsePlyrLink(url) {
        const regExp =
            /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/; // eslint-disable-line
        const match = url.match(regExp);

        if (match && match[1].length == 11) {
            return {
                src: match[1],
                provider: "youtube",
            };
        }

        if (url.split(".").pop() == "mp4") {
            return {
                src: url,
                type: "video/mp4",
            };
        }

        return null;
    }
}
