import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
// import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
// import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

import {
    IconModule,
    IconSetModule,
    IconSetService,
} from "@coreui/icons-angular";

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//     suppressScrollX: true
// };

import { AppComponent } from "./app.component";

// Import containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./pages/error/404.component";
import { P500Component } from "./pages/error/500.component";

const APP_CONTAINERS = [DefaultLayoutComponent];

import {
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
} from "@coreui/angular";

// Import routing module
import { AppRoutingModule } from "./app.routing";

// Import 3rd party components
import {
    BsDatepickerConfig,
    BsDaterangepickerConfig,
} from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { MODAL_CONFIG_DEFAULT_OVERRIDE } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";

import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { UsernamePipeModule } from "./@services/username-pipe/username-pipe.module";

// Interceptors
import { AuthInterceptor, ErrorInterceptor } from "@interceptors";

// Services
import { ConfigService } from "@services";

// Import locale module
import { AppLocaleModule } from "./app.locale";
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        HttpClientModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        AppLocaleModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        IconModule,
        IconSetModule.forRoot(),
        ToastrModule.forRoot({
            maxOpened: 1,
            preventDuplicates: true,
            autoDismiss: true,
            closeButton: true,
        }),
        NgxSpinnerModule,
        UsernamePipeModule
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        P404Component,
        P500Component,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        {
            provide: BsDatepickerConfig,
            useFactory: ConfigService.getDatepickerConfig,
        },
        {
            provide: BsDaterangepickerConfig,
            useFactory: ConfigService.getDaterangepickerConfig,
        },
        {
            provide: MODAL_CONFIG_DEFAULT_OVERRIDE,
            useFactory: ConfigService.getModalConfig,
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        IconSetService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
