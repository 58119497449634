import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IStatusUsername } from "../@interfaces/info-username.interface";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class ProfileService {
    constructor(private http: HttpClient) {}

    cekUsername(param) {
        return this.http.get<IStatusUsername>(
            `${environment.apiEndpoint}profile/username`,
            { params: param }
        );
    }

    changeUsername(body) {
        return this.http.patch<IStatusUsername>(
            `${environment.apiEndpoint}profile/username`,
            body
        );
    }

    requestEmailOtp() {
        return this.http.get(
            `${environment.apiEndpoint}profile-email/verification-email`
        );
    }

    resendEmailOtp() {
        return this.http.get(
            `${environment.apiEndpoint}profile-email/resend-code`
        );
    }

    verifyOtpVerifyEmail(otp) {
        return this.http.put(
            `${environment.apiEndpoint}profile-email/verification-email-code?code=${otp}`, {}
        );
    }

    verifyOtpChangeEmail(otp) {
        return this.http.put(
            `${environment.apiEndpoint}profile-email/change-email-code?code=${otp}`, {}
        );
    }

    changeEmail(payload) {
        return this.http.post(
            `${environment.apiEndpoint}profile-email/change-email`, payload
        );
    }
}
