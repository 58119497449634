export class Page {
    /**
     * The number of elements in the page
     *
     * @type number
     */

    private _size = 0;

    public get size() {
        return this._size;
    }

    public set size(input: number) {
        if (input < 0) {
            throw new Error("The size is invalid");
        }
        this._size = input;
    }

    /**
     * The total number of elements
     *
     * @type number
     */

    private _totalElements = 0;

    public get totalElements() {
        return this._totalElements;
    }

    public set totalElements(input: number) {
        if (input < 0) {
            throw new Error("The totalElements is invalid");
        }
        this._totalElements = input;
    }

    /**
     * The total number of pages
     *
     * @type number
     */

    private _totalPages = 0;

    public get totalPages() {
        return this._totalPages;
    }

    public set totalPages(input: number) {
        if (input < 0) {
            throw new Error("The totalPages is invalid");
        }
        this._totalPages = input;
    }

    /**
     * The current page number
     *
     * @type number
     */

    private _pageNumber = 0;

    public get pageNumber() {
        return this._pageNumber;
    }

    public set pageNumber(input: number) {
        if (input < 0) {
            throw new Error("The pageNumber is invalid");
        }
        this._pageNumber = input;
    }

    /**
     * Query Search params
     *
     * @type string
     */

    private _q?: string;

    public get q() {
        return this._q;
    }

    public set q(input: string) {
        if (input.length == 0) {
            throw new Error("The q is invalid");
        }
        this._q = input;
    }

    /**
     * Join params
     *
     * @type Array<string> defined format
     * @example relation||field1,field2
     */

    private _join: Array<string>;

    public get join() {
        return this._join;
    }

    public set join(input: Array<string>) {
        if (input.length == 0) {
            throw new Error("The join is invalid");
        }
        this._join = input;
    }

    /**
     * Filter params
     *
     * @type Array<string> defined format
     * @example field||$eq||value [id||$eq||123]
     */

    private _filter: Array<string>;

    public get filter() {
        return this._filter;
    }

    public set filter(input: Array<string>) {
        if (input.length == 0) {
            throw new Error("The filter is invalid");
        }
        this._filter = input;
    }

    /**
     * Sort params
     *
     * @type Array<string> defined format
     * @example field,ASC|DESC
     */

    private _sort: Array<string>;

    public get sort() {
        return this._sort;
    }

    public set sort(input: Array<string>) {
        if (input.length == 0) {
            throw new Error("The sort is invalid");
        }
        this._sort = input;
    }
}
