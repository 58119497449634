import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StorageMap } from "@ngx-pwa/local-storage";

import { StorageEnum } from "@enums";
import { environment } from "@env";
import { IAuth, IForgetPassword, IChangePassword, IStatusGoogle, IResponse } from "@interfaces";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    constructor(private storage: StorageMap, private http: HttpClient) { }

    async isAuthenticated(): Promise<boolean> {
        const token: string = await this.storage
            .get(StorageEnum.TOKEN_INFO, { type: "string" })
            .toPromise();
        if (!token) {
            return false;
        }

        return true;
    }

    register(data) {
        return this.http.post(`${environment.apiEndpoint}auth/register`, data);
    }

    login(data) {
        return this.http.put<IAuth>(
            `${environment.apiEndpoint}auth/login`,
            data
        );
    }

    forgotPassword(data) {
        return this.http.patch<IForgetPassword>(
            `${environment.apiEndpoint}auth/forgot-password`,
            data
        );
    }

    changePassword(id, params) {
        return this.http.patch<IChangePassword>(
            `${environment.apiEndpoint}auth/change-password/${id}`,
            params
        );
    }

    verify(data) {
        return this.http.patch(`${environment.apiEndpoint}auth/user-verification/${data}`, {});
    }

    reLogin() {
        return this.http.get<IAuth>(
            `${environment.apiEndpoint}auth/me`
        );
    }

    googleLogin() {
        return this.http.get(
            `${environment.apiEndpoint}auth/google-login`
        );
    }

    verifyGoogleLogin(params) {
        return this.http.get(
            `${environment.apiEndpoint}auth/google-login/redirect`,
            { params: params }
        );
    }

    googleStatus() {
        return this.http.get<IStatusGoogle>(
            `${environment.apiEndpoint}auth/google-connect/status-google`
        );
    }

    connectWithGoogle(params) {
        return this.http.get<IResponse>(
            `${environment.apiEndpoint}auth/google-connect/link-google`, { params: params }
        );
    }

    removeGoogle() {
        return this.http.get<IResponse>(
            `${environment.apiEndpoint}auth/google-connect/remove-google`
        );
    }

    refreshToken() {
        return this.http.get(
            `${environment.apiEndpoint}auth/refresh-token`
        );
    }
}