import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env";
import { IDataTable } from "@interfaces";
import { Page } from "@models";

import { HelperService } from "./helper.service";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    constructor(
        private http: HttpClient,
        private helperService: HelperService
    ) { }

    getList(page: Page) {
        const param = this.helperService.toPaginationParameter(page);

        return this.http.get<IDataTable>(
            `${environment.apiEndpoint}me/notifications`,
            { params: param }
        );
    }

    update(id, param) {
        return this.http.patch(
            `${environment.apiEndpoint}notifications/${id}`,
            param
        );
    }

    readAll() {
        return this.http.post(`${environment.apiEndpoint}me/notifications/read-all`, {});
    }
}
