import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env";

@Injectable({
    providedIn: "root",
})
export class TaskService {
    constructor(private http: HttpClient) {}

    getTaskStudents(params) {
        return this.http.get(
            environment.apiEndpoint + `student/task/list-week?${params}`
        );
    }

    getTaskDetails(params) {
        return this.http.get(
            environment.apiEndpoint +
                `student/task/${params.taskId}?classroom=${params.classroomId}`
        );
    }

    submitTaskStudent(data) {
        return this.http.post(
            environment.apiEndpoint + "student/task/submit",
            data
        );
    }
}
