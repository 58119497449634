import { Component } from "@angular/core";
import { StorageMap } from "@ngx-pwa/local-storage";
import { Router } from "@angular/router";
import { INavData } from "@coreui/angular";
import * as moment from "moment";

import {
    EventService,
    NotificationService,
    TaskService,
    HelperService,
    UserService,
    SharedService,
    AuthService,
} from "@services";
import { StorageEnum, ToastrEnum } from "@enums";
import { EventEnum } from "@enums";
import { Page } from "../../@models";
import { IDataTable, ISchool, IProfile, IUser } from "@interfaces";

@Component({
    selector: "app-dashboard",
    templateUrl: "./default-layout.component.html",
    styleUrls: ["./default-layout.component.scss"],
    providers: [SharedService],
})
export class DefaultLayoutComponent {
    public sidebarMinimized = false;
    public isReady = false;

    public navItems: INavData[] = [];
    page = new Page();
    listNotifications = [];
    counterUnRead = 0;
    totalNotif = 0;

    schoolInfo: ISchool;
    userProfile: IProfile;
    userInfo: IUser;
    isImageError = false;

    getStudentTaskQuery: string;

    constructor(
        public router: Router,
        private storage: StorageMap,
        private notificationService: NotificationService,
        private event: EventService,
        private taskService: TaskService,
        private helperService: HelperService,
        private userService: UserService,
        private sharedService: SharedService,
        public authService: AuthService
    ) {
        this.getSchoolInfo();
        this.getMenu();
        sharedService.changeEmitted$.subscribe((props) => {
            switch (props) {
            case "updatedProfile":
                this.getUserInfo();
                break;
            case "updatedNotification":
                this.getNotifications();
                break;
            case "requestRelogin":
                this.reloginUser();
                break;
            default:
                break;
            }
        });
    }

    ngOnInit() {
        // set page to get all data notif
        this.page.filter = ["isActive||$eq||true"];
        this.page.sort = ["createdAt,DESC"];
        this.page.pageNumber = 1;
        this.page.size = 100;
        // get data info from storage
        this.getSchoolInfo();
        this.getMenu();
        this.getUserInfo();
        this.getNotifications();
        // task query
        this.getStudentTaskQuery = new URLSearchParams({
            sort: "DESC",
            sortBy: "deadline",
        }).toString();
    }

    getMenu() {
        this.storage.get(StorageEnum.MENUS).subscribe((menu: INavData[]) => {
            this.navItems = menu;
            this.isReady = true;
        });
    }

    getSchoolInfo() {
        this.storage
            .get(StorageEnum.SCHOOL_INFO)
            .subscribe((school: ISchool) => {
                this.schoolInfo = school;
            });
    }

    async getUserInfo() {
        this.storage
            .get(StorageEnum.USER_INFO)
            .subscribe(async (user: IUser) => {
                this.userInfo = user;
                const data: any = await this.userService
                    .getProfileById(user.id)
                    .toPromise();
                this.userProfile = data;
            });
    }

    async reloginUser() {
        const response = await this.authService.reLogin().toPromise();
        if (response) {
            await this.storage
                .set(StorageEnum.TOKEN_INFO, response.token)
                .toPromise();
            await this.storage
                .set(StorageEnum.USER_INFO, response.user)
                .toPromise();
            if (response.user.student) {
                await this.storage
                    .set(StorageEnum.SCHOOL_INFO, response.user.student.school)
                    .toPromise();
                await this.storage
                    .set(StorageEnum.CLASS_INFO, response.user.student.classes)
                    .toPromise();
            } else {
                await this.storage
                    .set(StorageEnum.SCHOOL_INFO, {
                        name: "Sekolah Eduku",
                        email: "cs.info@eduku.id",
                        schoolType: "SMP",
                        logo: null,
                    })
                    .toPromise();
                await this.storage
                    .set(StorageEnum.CLASS_INFO, {
                        name: "0",
                        levelClass: 1,
                        teacher: "Guru Eduku",
                    })
                    .toPromise();
            }
            if (response.menus) {
                await this.storage
                    .set(StorageEnum.MENUS, response.menus)
                    .toPromise();
            }
        }
        this.getSchoolInfo();
        this.getMenu();
        this.getUserInfo();
    }

    async getNotifications() {
        // get all notif
        const response: IDataTable = await this.notificationService
            .getList(this.page)
            .toPromise();
        this.totalNotif = response.total;
        this.page.pageNumber = response.page;
        this.page.totalPages = response.pageCount;
        this.page.totalElements = response.total;

        const currentDate = moment();

        const data: Array<object> = response.data;

        this.counterUnRead = 0;

        const notificationData = data.filter(
            (notification: { [key: string]: string }, idx) => {
                if (!notification.is_read) {
                    this.counterUnRead++;
                }

                const createdDate = moment(notification.createdAt);
                const daysDiff = currentDate.diff(createdDate, "days");

                if (daysDiff > 0) {
                    notification.dateString =
                        createdDate.format("D MMM YYYY, HH:mm");
                } else {
                    const hourDiff = currentDate.diff(createdDate, "hours");
                    const minuteDiff = currentDate.diff(createdDate, "minutes");

                    if (hourDiff > 0) {
                        notification.dateString = `${hourDiff} jam yang lalu`;
                    } else if (minuteDiff > 0) {
                        notification.dateString = `${minuteDiff} menit yang lalu`;
                    } else {
                        notification.dateString = "Baru saja";
                    }
                }

                if (idx < 10) {
                    return true;
                }

                return false;
            }
        );

        this.listNotifications = notificationData;
    }

    async readNotification(data) {
        const type = data.notificationType;
        let task: { [key: string]: string | any };
        let tasks: any;
        // unread notif
        if (!data.is_read) {
            data.is_read = true;
            await this.notificationService.update(data.id, data).toPromise();
        }
        // go to router page
        switch (type) {
        case "attendance_reminder":
        case "classroom_reminder":
            if (data.classroom.id) {
                this.router.navigate([`schedule/${data.classroom.id}`], {
                    replaceUrl: true,
                });
            } else {
                this.router.navigate(["schedule"]);
            }
            break;
        case "task_assignment":
        case "assignment_reminder":
        case "task_comment":
            tasks = await this.getTaskStudents(data.classroom.id);
            if (tasks) {
                if (tasks.length > 0) {
                    tasks.forEach((el) => {
                        if ((el.id = data.task.id)) {
                            task = el;
                        }
                    });
                    await this.storage
                        .set(StorageEnum.TASK, task)
                        .toPromise();
                    this.router.navigate([`task/details/${task.id}`], {
                        replaceUrl: true,
                    });
                }
            } else {
                this.router.navigate(["task"], {
                    replaceUrl: true,
                });
            }
            break;
        case "join_request_approved":
            this.logout();
            break;
        case "exam_published":
            this.router.navigate(["exam"], { replaceUrl: true });
            break;
        default:
            this.router.navigate(["dashboard"], { replaceUrl: true });
            break;
        }
        this.getNotifications();
    }

    toggleMinimize(e) {
        this.sidebarMinimized = e;
    }

    async logout() {
        await this.storage.clear().toPromise();
        this.router.navigate(["login"], { replaceUrl: true });
    }

    async getTaskStudents(idClassroom) {
        try {
            this.event.publish({
                type: EventEnum.IS_LOADING,
                loading: true,
            });

            const data: any = await this.taskService
                .getTaskStudents(this.getStudentTaskQuery)
                .toPromise();
            if (data) {
                if (data.length > 0) {
                    data.forEach((el) => {
                        el.courseId = el.rpp.course.id;
                        el.course = el.rpp.course.name;
                        el.tasks[0].rppId = el.rpp.id;
                        el.tasks[0].classroomId = el.id;
                        el.tasks[0].course = el.rpp.course.name;
                        el.tasks[0].startDate = el.startDate;
                    });
                }

                let foundClassroom: any;
                data.forEach((el) => {
                    if (el.id === idClassroom) {
                        foundClassroom = el;
                    }
                });
                if (foundClassroom) {
                    let tasks = foundClassroom.tasks;
                    tasks = tasks.map((item, index) => {
                        return {
                            indexCol: index + 1,
                            id: item.task.id,
                            taskId: item.id,
                            rppId: item.rppId,
                            classroomId: item.classroomId,
                            title: item.task.title,
                            courseName: item.course,
                            type: item.task.type,
                            startDate: item.startDate,
                            endDate: item.deadline,
                            deadline: this.helperService.changeFormatDate(
                                item.deadline,
                                "MM-DD-YYYY"
                            ),
                            status: item.studentTask.length > 0 ? true : false,
                        };
                    });

                    return tasks;
                }
            }
        } finally {
            this.event.publish({
                type: EventEnum.IS_LOADING,
                loading: false,
            });
        }
    }

    async readAllNotif() {
        try {
            const res = await this.notificationService.readAll().toPromise();
            if (res) {
                this.event.publish({
                    type: EventEnum.TOASTR,
                    toastr: {
                        type: ToastrEnum.SUCCESS,
                        title: "Berhasil",
                        message: "Semua notifikasi berhasil di baca",
                        config: { positionClass: "toast-bottom-center" },
                    },
                });
                this.counterUnRead = 0;
                this.getNotifications();
            }
        } catch (err) {
            this.event.publish({
                type: EventEnum.TOASTR,
                toastr: {
                    type: ToastrEnum.ERROR,
                    title: "Gagal!!",
                    message: "Upss, gagal membaca notifikasi, silahkan coba kembali",
                    config: { positionClass: "toast-bottom-center" },
                },
            });
        }
    }
}
