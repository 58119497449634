import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env";
import { IAttendance, IAuth, IClassroom, IDiscussion } from "@interfaces";

@Injectable({
    providedIn: "root",
})
export class ClassroomService {
    constructor(private http: HttpClient) {}

    requestAccess(code) {
        return this.http.patch<IAuth>(
            environment.apiEndpoint + "student/verify/" + code,
            null
        );
    }

    cancelRequest() {
        return this.http.patch(
            environment.apiEndpoint + "student/cancel-request",
            null
        );
    }

    getStudentSchedule(startDate, endDate) {
        return this.http.get<Array<IClassroom>>(
            environment.apiEndpoint +
                "class-room/list" +
                `?startDate=${startDate}` +
                `&endDate=${endDate}`
        );
    }

    getStudentScheduleById(id) {
        return this.http.get<IClassroom>(
            environment.apiEndpoint + "class-room/" + id
        );
    }

    getClassmates() {
        return this.http.get(environment.apiEndpoint + "student/classmate");
    }

    getTeacherList() {
        return this.http.get(environment.apiEndpoint + "student/staff-school");
    }

    getStudentBasicInfo() {
        return this.http.get(environment.apiEndpoint + "dashboard/student/basic-info");
    }

    getAttendanceList(id) {
        return this.http.get<IAttendance>(
            `${environment.apiEndpoint}class-room/attendance/list/${id}`
        );
    }

    getDiscussion(id) {
        return this.http.get<Array<IDiscussion>>(
            `${environment.apiEndpoint}class-room/discussion/${id}`
        );
    }

    postDiscussion(id, message) {
        return this.http.post(
            `${environment.apiEndpoint}class-room/discussion`,
            { classroom: id, message }
        );
    }

    postPresence(data) {
        return this.http.post(
            `${environment.apiEndpoint}student/absences/present`,
            data
        );
    }

    getListModule(params? : any) {
        return this.http.get<Array<IClassroom>>(
            `${environment.apiEndpoint}student/modul/list-week`, { params: params }
        );
    }
}
