export enum StorageEnum {
    TOKEN_INFO = "TOKEN_INFO",
    USER_INFO = "USER_INFO",
    SCHOOL_INFO = "SCHOOL_INFO",
    CLASS_INFO = "CLASS_INFO",
    MENUS = "MENUS",
    TASK = "TASK",
    QUIZ = "QUIZ",
    EXAM = "EXAM",
    SETDATE = "SETDATE",
    COUNTDOWN = "COUNTDOWN",
}
