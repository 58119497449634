import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IndividualConfig } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { EventEnum, ToastrEnum } from "@enums";
import { EventService } from "@services";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private eventService: EventService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {

                const errorMessage = err.error.message || err.message;
                const errorCode = err.status || err.error.code || 0;
                const toastrConfig: Partial<IndividualConfig> = { positionClass: "toast-bottom-center" };

                this.eventService.publish({
                    type: EventEnum.IS_LOADING,
                    loading: false
                });

                if (errorCode == 0) {
                    this.eventService.publish({
                        type: EventEnum.TOASTR,
                        toastr: {
                            type: ToastrEnum.ERROR,
                            title: "Koneksi bermasalah",
                            message: "Ada masalah dengan koneksi, silahkan coba beberapa saat lagi",
                            config: toastrConfig
                        }
                    });
                }
                else if (errorCode == 401) {                    
                    this.eventService.publish({
                        type: EventEnum.TOASTR,
                        toastr: {
                            type: ToastrEnum.ERROR,
                            title: "Gagal autentikasi",
                            message: errorMessage,
                            config: toastrConfig
                        }
                    });

                    this.router.navigate(["login"]);
                }
                else {
                    this.eventService.publish({
                        type: EventEnum.TOASTR,
                        toastr: {
                            type: ToastrEnum.ERROR,
                            title: "Kesalahan",
                            message: errorMessage,
                            config: toastrConfig
                        }
                    });
                }

                return throwError(err);
            })
        );
    }
}