import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env";
import { IStudentProfile } from "@interfaces";

@Injectable({
    providedIn: "root",
})
export class StudentService {
    constructor(private http: HttpClient) { }

    getStudentProfileFromCode(code) {
        return this.http.get<IStudentProfile>(`${environment.apiEndpoint}student/profile/${code}`);
    }

}